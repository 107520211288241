import React, { useState } from "react";
import { Link, graphql } from "gatsby";
import Seo from "../components/seo";
import Layout from "../components/layout";
import {
  search,
  searchBox,
  searchInput,
  category,
  cardList,
  cardItem,
  cardLink,
  cardCategory,
  cardTitle,
  cardBody,
  cardDate,
} from "./index.module.css";

const IndexPage = ({ data }) => {
  const allPosts = data.allMarkdownRemark.edges;
  const emptyQuery = "";

  const [state, setState] = useState({
    filteredData: [],
    query: emptyQuery,
  });

  const handleInputChange = (event) => {
    const query = event.target.value;

    const posts = data.allMarkdownRemark.edges || [];

    const filteredData = posts.filter((post) => {
      const { description, title } = post.node.frontmatter;

      return (
        description.toLowerCase().includes(query.toLowerCase()) ||
        title.toLowerCase().includes(query.toLowerCase())
      );
    });

    setState({
      query,
      filteredData,
    });
  };

  const { filteredData, query } = state;
  const hasSearchResults = filteredData && query !== emptyQuery;
  const posts = hasSearchResults ? filteredData : allPosts;

  return (
    <Layout>
      <Seo />
      <main>
        <div className={search}>
          <div className={searchBox}>
            <input
              type="text"
              placeholder="キーワードを入力..."
              onChange={handleInputChange}
              className={searchInput}
            />
          </div>
        </div>
        <div className={category}>
          <h2>Category : All</h2>
        </div>
        <div className={cardList}>
          {posts.map(({ node }) => (
            <div className={cardItem} key={node.frontmatter.slug}>
              <Link to={`/${node.frontmatter.slug}`} className={cardLink}>
                <div className={cardCategory}>
                  {node.frontmatter.slug.split("/")[0]}
                </div>
                <div className={cardBody}>
                  <div className={cardTitle}>{node.frontmatter.title}</div>
                  <div className={cardDate}>{node.frontmatter.date}</div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </main>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          frontmatter {
            slug
            title
            description
            date(formatString: "YYYY.MM.DD")
            category
            tags
          }
          excerpt
        }
      }
    }
  }
`;

export default IndexPage;
